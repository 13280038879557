import React, { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Button, Autocomplete, NumberInput, Pagination, Modal, TextInput, Radio, Notification } from '@mantine/core';
import { IconSearch, IconCheck, IconX } from '@tabler/icons';
import { useCookies } from 'react-cookie';
import { useForm } from '@mantine/form';
import http from '../axios/index';
import ErrorPage from "./errors/401";

export default function Bearing() {

  const[search, setSearch] = useState("");

  // Fetching
  const [fetching, setFetching] = useState(false);

  // Buttons Loading
  const [buttonLoading, setButtonLoading] = useState(false);

  // Dialogs
  const [openedDialog, setOpenedDialog] = useState(false);
  const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);
  const [openedDetails, setOpenedDetailsDialog] = useState(false);

  // Dialog Title and Button
  const [title, setTitle] = useState("")
  const [button, setButton] = useState("")
  const [modelType, setModelType] = useState("create")

  // Bearing Data
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);


  // On Page Load
  useEffect(()=>{
    getBearings(1);
  },[search]);

  // Bearing form
  const form = useForm({
    initialValues: {
      id: '',
      name: '',
      number: 0,
      height: 0,
      width: 0,
      stock: 0,
      company: '',
    },

    // Form Validations
    validate: {
      name: (value) => (value.length < 2 ? 'Name must have at least 2 letters' : null),
      number: (value) => (value ?  null: 'Number field required'),
      stock: (value) => (button === 'Save' ? (value ? null : 'Stock field required') : null),
    },
  });

  // Stock form
  const stockForm = useForm({
    initialValues: {
      id: '',
      stock: 0,
      quantity_type: 'Add',
      quantity: 0,
    },
  });

  // Functions

  function resetForm() {
    form.reset();
    form.values.id = '';
    form.values.name = '';
    form.values.number = 0;
    form.values.height = 0;
    form.values.width = 0;
    form.values.stock = 0;
    form.values.company = '';
  }

  // Get bearings
  function getBearings(page: any) {
    setFetching(true);
    http.get(`bearing/?page=${page}&search=${search}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setData(response.data.data.data);
      setTotal(response.data.data.total / response.data.data.per_page);
      setCurrentPage(response.data.data.current_page);
      setFetching(false);
    });
  }

  // Create Inventory Function
  const createBearing = () => {
    setModelType('create')
    setTitle('Create New Inventory!');
    setButton('Save');
    resetForm();
    setOpenedDialog(true);
  }

  // Add Bearing
  function addBearing() {
    setButtonLoading(true);
    http.post(`bearing`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      getBearings(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Bearing created successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      setOpenedDialog(false);
    }) 
  }

  // Edit Bearing Function
  const editBearing = ({id, name, number, height, width, company}: any) => {
    setModelType('edit')
    setTitle('Edit Inventory!');
    setButton('Update');
    resetForm();
    form.setFieldValue('id', id);
    form.setFieldValue('name', name);
    form.setFieldValue('number', number);
    form.setFieldValue('height', height ? height : 0);
    form.setFieldValue('width', width ? width : 0);
    form.setFieldValue('company', company ? company : '');
    setOpenedDialog(true);
  }

  // Update Bearing
  function updateBearing() {
    setButtonLoading(true);
    http.put(`bearing/${form.values.id}`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      getBearings(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Bearing updated successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      setOpenedDialog(false);
    }) 
  }

  // Select bearing to delete
  const deleteBearing = ({id}: any) => {
    form.values.id = id;
    setOpenedDeleteDialog(true);
  }

  // Delete Bearing
  function deleteSelectedBearing() {
    setButtonLoading(true);
    http.delete('bearing/' + form.values.id, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      if (!response.data.data) {
        setButtonLoading(false);
        setNotificationType(false);
        setNotification(response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      } else {
        resetForm();
        setOpenedDeleteDialog(false);
        getBearings(1);
        setNotificationType(true);
        setNotification('Bearing deleted successfully!');
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    })
    .catch((error) => {
      setButtonLoading(false);
    }) 
  }

  // Details Inventory Function
  const detailBearing = ({id, stock}: any) => {
    stockForm.reset();
    stockForm.values.id = '';
    stockForm.values.stock = 0;
    stockForm.values.quantity_type = 'Add';
    stockForm.values.quantity = 0;
    stockForm.setFieldValue('id', id);
    stockForm.setFieldValue('stock', stock.stock);
    setOpenedDetailsDialog(true);
  }

  // Details Inventory
  function updateStock() {
    setButtonLoading(true);
    http.post('update-stock', stockForm.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      setButtonLoading(false);
      setOpenedDetailsDialog(false);
      getBearings(1);
      setNotificationType(true);
      setNotification('Bearing stock updated successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
    }) 
  }

  const getStockInput = () => {
    if(modelType === 'create') {
      return (
       <NumberInput mt="sm" 
        placeholder="Enter Stock" 
        label="Stock" hideControls 
        withAsterisk {...form.getInputProps('stock')}  />
      )
    }
  }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  const [cookies] = useCookies(['user', 'token', 'abilities']);
  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Bearing' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  return (
    <>
      {renderNotification()}
      <div className="title-section">
        <h2 className="d-inline-block mr-10">Bearings</h2>
        <div className="d-inline-block float-right-md mb-15">
          <Button onClick={() => createBearing()} color="green" className="mt-15" mr={10}>Create New Bearing</Button>
          <TextInput icon={<IconSearch />} placeholder="Search Bearing..." className="d-inline-block mt-15" style={{width: 200}} value={search} onChange={(e) => setSearch(e.target.value)} withAsterisk />
        </div>
      </div>

      <DataTable
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        minHeight={100}
        records={data}
        columns={[
          {
            accessor: 'id',
            title: 'ID',
            textAlignment: 'center',
            width: 15,
          },
          { accessor: 'name' },
          { accessor: 'number' },
          { accessor: 'company' },
          { accessor: 'stock',
            render: ({ id, stock }) => (
              <>
                <Button color="orange" onClick={() => detailBearing({id, stock})}>
                  Details
                </Button>
              </>
            ),
            textAlignment: 'center',
            width: 100,
          },
          { accessor: 'actions',
            render: ({ id, name, number, height, width, company }) => (
              <>
                <Button onClick={() => editBearing({id, name, number, height, width, company})}>
                  Edit
                </Button>
                <Button ml={10} color="red" onClick={() => deleteBearing({id})}>
                  Delete
                </Button>
              </>
            ),
            textAlignment: 'center',
            width: 200,
          },
        ]}
      />

      <Modal overflow="outside"
        opened={openedDialog}
        onClose={() => setOpenedDialog(false)}
        title={title}
      >
        <form onSubmit={form.onSubmit((values) =>  button === 'Save' ? addBearing() : updateBearing())}>
          <TextInput mt="sm" label="Name" placeholder="Enter Name" withAsterisk {...form.getInputProps('name')} />
          <NumberInput mt="sm" placeholder="Enter Number" label="Number" hideControls withAsterisk {...form.getInputProps('number')}  />
          <NumberInput mt="sm" label="Height" placeholder="Enter Height" hideControls {...form.getInputProps('height')} />
          <NumberInput mt="sm" label="Width" placeholder="Enter Width" hideControls {...form.getInputProps('width')} />
          {getStockInput()}
          <TextInput mt="sm" label="Company" placeholder="Enter Company" {...form.getInputProps('company')} />
          <div className="text-right">
            <Button color="red" type="button" mt="sm" onClick={() => setOpenedDialog(false)}>
              Cancel
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              {button}
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        opened={openedDeleteDialog}
        onClose={() => setOpenedDeleteDialog(false)}
        title="Confirm!"
      >
        <form>
          <h3>Are You Sure You Want To Delete?</h3>
          {renderNotification()}
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedDeleteDialog(false)}>
              No
            </Button>
            <Button color="green" type="button" ml="sm" onClick={() => deleteSelectedBearing()} loading={buttonLoading}>
              Yes
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        opened={openedDetails}
        onClose={() => setOpenedDetailsDialog(false)}
        title="Update Stock!"
      >
        <form onSubmit={stockForm.onSubmit((values) => updateStock())}>
         <NumberInput
            disabled
            mt="sm"
            label="Stock"
            placeholder="Enter Stock"
            withAsterisk {...stockForm.getInputProps('stock')} 
          />
          <Radio.Group
            mt="sm"
            name="quantityType"
            label="Select Employee Status"
            description=""
            withAsterisk {...stockForm.getInputProps('quantity_type')} 
          >
            <Radio value="Add" label="Add" />
            <Radio value="Withdraw" label="Withdraw" />
          </Radio.Group>
          <NumberInput
            mt="sm"
            label="Quantity"
            placeholder="Enter Quantity"
            withAsterisk
            hideControls
            {...stockForm.getInputProps('quantity')} 
          />
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedDetailsDialog(false)}>
              No
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              Yes
            </Button>
          </div>
        </form>
      </Modal>
    
      <Pagination total={total} page={current_page} onChange={(e) => getBearings(e)} className="justify-content-center" mt={15} />
    </>
  );
}