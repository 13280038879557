import React, { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Button, Pagination, Modal, TextInput, Radio, Select, Notification, Image } from '@mantine/core';
import { IconSearch, IconCheck, IconX } from '@tabler/icons';
import { TimeInput, DatePicker } from '@mantine/dates';
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import ErrorPage from "./errors/401";
import http from '../axios/index';
import { useForm } from '@mantine/form';
import moment from "moment";

export default function Attendance() {
  const navigate = useNavigate();
  
  function handleClick(employee: any) {
    navigate(`/calender/${employee.id}`);
  }

  // Dialogs
  const [openedAttendanceDialog, setOpenedAttendanceDialog] = useState(false);
  const [openedTimeOut, setOpenedTimeOut] = useState(false);
  const [openedOthers, setOpenedOthers] = useState(false);
  const [openedEdit, setOpenedEdit] = useState(false);

  const[search, setSearch] = useState("");

  // Fetching
  const [fetching, setFetching] = useState(false);

  // Buttons Loading
  const [buttonLoading, setButtonLoading] = useState(false);

  // Employees with label and value
  const [employeesData, setEmployeesData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

  const [data, setData] = useState([]);

  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  // Disable Button
  const [disabled, setDisabled] = useState(false);

  // Error message on time in form
  const [timeInErrorMessage, setTimeInErrorMessage] = useState("");

  // Error message on time out form
  const [timeOutErrorMessage, setTimeOutErrorMessage] = useState("");

  // Error message on absence form
  const [absenceErrorMessage, setAbsenceErrorMessage] = useState("");

  // Error message on previous attendance form
  const [previousErrorMessage, setPreviousErrorMessage] = useState("");

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);

  // On Page Load
  useEffect(()=>{
    getEmployees(1);
  },[search]);

  // Time in form
  const timeInForm = useForm({
    initialValues: {
      employee: '',
      time_in: new Date(),
      date: new Date(),
      note: '',
    },

    // Time in Form Validation
    validate: {
      employee: (value) => (value.length < 1 ? 'Please Select Employee' : null),
    },
  });

  // Time out form
  const timeOutForm = useForm({
    initialValues: {
      employee: '',
      time_in: null,
      time_out: new Date(),
      date: new Date(),
      note: '',
    },

    // Time out Form Validation
    validate: {
      employee: (value) => (value.length < 1 ? 'Please Select Employee' : null),
    },
  });

  // Absent/Leave form
  const absenceForm = useForm({
    initialValues: {
      employee: '',
      absence_status: 'absent',
      time_in: null,
      time_out: null,
      date: new Date(),
      note: '',
    },

    // Absence Form Validation
    validate: {
      employee: (value) => (value.length < 1 ? 'Please Select Employee' : null),
    },
  });

  // Previous Attendance form
  const previousAttendanceForm = useForm({
    initialValues: {
      employee: '',
      date: new Date(),
      type: 'absent',
      time_in: null,
      time_out: null,
      note: '',
    },

    // Absence Form Validation
    validate: {
      date: (value) => (value ? null : 'Please Select Date'),
    },
  });

  // Get Employees
  function getEmployees(page: any) {
    setFetching(true);
    http.get(`employee/?page=${page}&search=${search}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setData(response.data.data.data);
      setTotal(response.data.data.total / response.data.data.per_page);
      setCurrentPage(response.data.data.current_page);
      response.data.data.data.map((item: any) => setEmployeesData((curData) => [...curData, { value: item, label: item.name }]));
      setFetching(false);
    });
  }

  //Check details before Time in request
  function checkTimeInRequest(employee: any) {
    timeInForm.setFieldValue('employee', employee);
    if(employee.attendances.length) {
      let last_attendance = employee.attendances[0];
      let current_date = moment().format("YYYY-MM-DD");
      let last_attendance_date = moment(last_attendance.date).format("YYYY-MM-DD");
      if(current_date === last_attendance_date) {
        if(last_attendance.name === "time_in" || last_attendance.name === "absent" || last_attendance.name === "leave") {
          setDisabled(true);
          setTimeInErrorMessage('Already Marked!');
        } else {
          setDisabled(false);
          setTimeInErrorMessage("");  
        }
      } else {
        setDisabled(false);
        setTimeInErrorMessage("");
      }
    } else {
      setDisabled(false);
      setTimeInErrorMessage("");
    }
  }

  // Submit Time in
  function submitTimeIn() {
    setButtonLoading(true);
    http.post('attendance', timeInForm.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      console.log(response);
      setButtonLoading(false);
      getEmployees(1);
      timeInForm.reset();
      setOpenedAttendanceDialog(false);
      setNotificationType(true);
      setNotification('Saved successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      console.log(error);
      setButtonLoading(false);
      getEmployees(1);
      timeInForm.reset();
      setOpenedAttendanceDialog(false);
    })
  }

  //Check details before Time out request
  function checkTimeOutRequest(employee: any) {
    timeOutForm.setFieldValue('employee', employee);
    if(employee.attendances.length) {
      let last_attendance = employee.attendances[0];
      if(last_attendance.name === 'time_in') {
        setDisabled(false);
        setTimeOutErrorMessage("");
      } else {
        setDisabled(true);
        setTimeOutErrorMessage("User not Time in!");
      }
    } else {
      setDisabled(true);
      setTimeOutErrorMessage("");
    }
  }

  // Submit Time out
  function submitTimeOut() {
    setButtonLoading(true);
    http.post('attendance', timeOutForm.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      console.log(response);
      setButtonLoading(false);
      getEmployees(1);
      timeOutForm.reset();
      setOpenedTimeOut(false);
      setNotificationType(true);
      setNotification('Saved successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      console.log(error);
      setButtonLoading(false);
      getEmployees(1);
      timeOutForm.reset();
      setOpenedTimeOut(false);
    })
  }

  //Check details before Absence(leave,absent) request
  function checkAbsenceRequest(employee: any) {
    absenceForm.setFieldValue('employee', employee);
    if(employee.attendances.length) {
      let last_attendance = employee.attendances[0];
      let current_date = moment().format("YYYY-MM-DD");
      let last_attendance_date = moment(last_attendance.date).format("YYYY-MM-DD");
      if(current_date === last_attendance_date) {
        if(last_attendance.name === "time_in" || last_attendance.name === "time_out" || last_attendance.name === "absent" || last_attendance.name === "leave") {
          setDisabled(true);
          setAbsenceErrorMessage('Alreay Marked!');
        } else {
          setDisabled(false);
          setAbsenceErrorMessage("");  
        }
      } else {
        setDisabled(false);
        setAbsenceErrorMessage("");
      }
    } else {
      setDisabled(false);
      setTimeInErrorMessage("");
    }
  }

  //Submit Absent or Leave
  function submitAbsence() {
    setButtonLoading(true);
    http.post('attendance', absenceForm.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      console.log(response);
      setButtonLoading(false);
      getEmployees(1);
      absenceForm.reset();
      setOpenedOthers(false);
      setNotificationType(true);
      setNotification('Saved successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      console.log(error);
      setButtonLoading(false);
      getEmployees(1);
      absenceForm.reset();
      setOpenedOthers(false);
    })
  }

  // Check details before previous Attendance
  function checkPreviousAttendanceRequest(date: any) {
    previousAttendanceForm.setFieldValue('date', date);
    let selectedDate = moment(date).format("YYYY-MM-DD");
    let employee: any = previousAttendanceForm.values.employee;
    let employee_attendances: any = employee.attendances;

    var selected_date_record = employee_attendances.filter((i: { date: string; }) => i.date === selectedDate);
    if(selected_date_record.length) {
      // console.log(selected_date_record);
      const data = selected_date_record.find((i: { name: string; }) => i.name === 'time_in' || i.name === 'time_out' || i.name === 'leave' || i.name === 'absent' );
      // console.log(data);
      if(data.name === 'leave' || data.name === 'absent' || data.name === 'time_in' || data.name === 'time_out') {
        setPreviousErrorMessage('Alreay Marked on selected Date!');
        setDisabled(true);
      } else {
        // console.log('can mark attendance');
        setPreviousErrorMessage("");
        setDisabled(false);
      }
    } else {
        setPreviousErrorMessage("");
        setDisabled(false);
    }
  }

  // Submit Previous Attendance
  function submitPreviousAttendance() {
    let date = moment(previousAttendanceForm.values.date).format("YYYY-MM-DD");
    let employee: any = previousAttendanceForm.values.employee;
    let attendance_match = false;
    
    // Check previous attendance does not exist in this date
    for(let i = 0; i < employee.attendances.length; i++) {
      if (employee.attendances[i].date === date) {
        attendance_match = true;
        break;
      }
    }

    if (attendance_match) {
      previousAttendanceForm.setFieldError('date', 'Already Marked!');
    } else {
      setButtonLoading(true);
      http.post('previous-attendance', previousAttendanceForm.values, {
        headers: { Authorization: 'Bearer '+cookies.token }
      })
      .then((response) => {
        console.log(response);
        setButtonLoading(false);
        getEmployees(1);
        previousAttendanceForm.reset();
        setOpenedEdit(false);
        setNotificationType(true);
        setNotification('Saved successfully!');
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      })
      .catch((error) => {
        console.log(error);
        setButtonLoading(false);
        getEmployees(1);
        previousAttendanceForm.reset();
        setOpenedEdit(false);
      });
    }
  }

  // Render Attendances in DataTable
  function displayAttendances(attendance: any) {
    if (attendance.name === 'absent' || attendance.name === 'leave') {
      return (
        <p className="mb-5 mt-0">{attendance.name}</p> 
      );
    } else {
      return (
        <p className="mb-5 mt-0"><strong>{attendance.name.replaceAll('_', ' ')}</strong>: {moment(attendance.value, "hh:mm A").format("hh:mm A")}</p> 
      );
    }
  }

  const [cookies] = useCookies(['user', 'token', 'abilities']);
  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Attendance' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  // Time in Attendance Function
  const timeInAttendance = () => {
    timeInForm.reset();
    setTimeInErrorMessage("");
    setDisabled(false);
    setOpenedAttendanceDialog(true);
  }

  // Time Out Attendance Function
  const timeOutAttendance = () => {
    timeOutForm.reset();
    setTimeOutErrorMessage("");
    setDisabled(false);
    setOpenedTimeOut(true);
  }

  // Others Attendance Function
  const othersAttendance = () => {
    absenceForm.reset();
    setAbsenceErrorMessage("");
    setDisabled(false);
    setOpenedOthers(true);
  }

  // Edit Attendance Function
  const editAttendance = (employee: any) => {
    previousAttendanceForm.reset();
    previousAttendanceForm.values.date = new Date();
    previousAttendanceForm.setFieldValue('employee', employee);
    setPreviousErrorMessage("");
    setDisabled(false);
    setOpenedEdit(true);
  }

  // Present inputs condition
  const renderTimeInOut = () => {
    if (previousAttendanceForm.values.type === 'present') {
      return (
        <>
          <TimeInput label="Time In" format="12" {...previousAttendanceForm.getInputProps('time_in')} />
          <TimeInput label="Time Out" format="12" {...previousAttendanceForm.getInputProps('time_out')} />
        </>
      );
    }
  }

  return (
    <>
      {renderNotification()}
      <div className="title-section">
        <h2 className="d-inline-block mr-10">Employees Attendance</h2>
        <div className="d-inline-block float-right-md mb-15">
          <Button color="green" onClick={() => timeInAttendance()} className="mt-15" mr={10}>Time In</Button>
          <Button onClick={() => timeOutAttendance()} className="mt-15" mr={10}>Time Out</Button>
          <Button color="red" type="submit" onClick={() => othersAttendance()} className="mt-15" mr={10}>Other</Button>
          <TextInput icon={<IconSearch />} placeholder="Search Employee..." className="d-inline-block mt-15" style={{width: 200}} value={search} onChange={(e) => setSearch(e.target.value)} withAsterisk />
        </div>
      </div> 
        
      <DataTable mt={15}
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        records={data}
        minHeight={100}
        fetching={fetching}
        columns={[
          {
            accessor: 'id',
            textAlignment: 'left',
          },
          { accessor: 'name' },
          {
            accessor: 'image',
            render: ({ image, name  }) => (
              <Image
                radius="md"
                src={`${process.env.REACT_APP_WEB_URL}${image ? image : 'assets/images/company-placeholder.jpg'}`}
                alt={name}
                height={75}
                width={75}
              />
            ),
          },
          {
            accessor: 'attendances',
            render: (employee: any) => (
              <>{employee.current_date_attendances.length ?  employee.current_date_attendances.map((item: any) => displayAttendances(item)) : ''}</>
            )
          },
          { accessor: 'note'},
          {
            accessor: 'actions',
            // this column has custom cell data rendering
            render: (employee: any) => (
              <>
                <Button onClick={() => editAttendance(employee)}>
                  Edit
                </Button>
                <Button ml={10} color="orange" onClick={() => handleClick(employee)}>
                  Details
                </Button>
              </>
            ),
          },
        ]}
      />

      <Modal
        opened={openedAttendanceDialog}
        onClose={() => setOpenedAttendanceDialog(false)} 
        title="Time In!"
      >
        <form onSubmit={timeInForm.onSubmit((values) => submitTimeIn())}>
          <Select
            mt={12}
            label="Employee"
            placeholder="Select Employee"
            searchable
            data={employeesData}
            withAsterisk
            onChange={(e) => checkTimeInRequest(e) }
          />
          <p className="error-message">{timeInErrorMessage}</p>
          <TimeInput label="Time In" format="12" withAsterisk {...timeInForm.getInputProps('time_in')} />
          <TextInput mt="sm" label="Note" placeholder="Note" {...timeInForm.getInputProps('note')}/>
          <Button color="red" type="button" ml={194} mt="sm" onClick={() => setOpenedAttendanceDialog(false)}>
            Cancel
          </Button>
          <Button color="green" type="submit" ml="sm" loading={buttonLoading} disabled={disabled}>
            Time In
          </Button>
        </form>
      </Modal>
      
      <Modal
        opened={openedTimeOut}
        onClose={() => setOpenedTimeOut(false)}
        title="Time Out!"
      >
        <form onSubmit={timeOutForm.onSubmit((values) => submitTimeOut())}>
          <Select
            mt={12}
            label="Employee"
            placeholder="Select Employee"
            searchable
            data={employeesData}
            withAsterisk
            onChange={(e) => checkTimeOutRequest(e) }
          />
          <p className="error-message">{timeOutErrorMessage}</p>
          <TimeInput label="Time Out" format="12" withAsterisk {...timeOutForm.getInputProps('time_out')} />
          <TextInput mt="sm" label="Note" placeholder="Note" {...timeOutForm.getInputProps('note')} />
          <Button color="red" type="button" ml={183} mt="sm" onClick={() => setOpenedTimeOut(false)}>
            Cancel
          </Button>
          <Button color="green" type="submit" ml="sm" loading={buttonLoading} disabled={disabled}>
            Time Out
          </Button>
        </form>
      </Modal>
      
      <Modal
        opened={openedOthers}
        onClose={() => setOpenedOthers(false)}
        title="Absense Form!"
      >
        <form onSubmit={absenceForm.onSubmit((values) => submitAbsence())}>
          <Select
            mt={12}
            label="Employee"
            placeholder="Select Employee"
            searchable
            data={employeesData}
            withAsterisk
            onChange={(e) => checkAbsenceRequest(e) }
          />
          <p className="error-message">{absenceErrorMessage}</p>
          <Radio.Group
            label="Select Absense Status"
            withAsterisk
            {...absenceForm.getInputProps('absence_status')}
          >
            <Radio value="absent" label="Absent" />
            <Radio value="leave" label= "Leave" />
          </Radio.Group>
          <TextInput mt="sm" label="Note" placeholder="Note" {...absenceForm.getInputProps('note')}/>
          <Button color="red" type="button" ml={212} mt="sm" onClick={() => setOpenedOthers(false)}>
            Cancel
          </Button>
          <Button color="green" type="submit" ml="sm" loading={buttonLoading} disabled={disabled}>
            Save
          </Button>
        </form>
      </Modal>
      
      <Modal
        opened={openedEdit}
        onClose={() => setOpenedEdit(false)}
        title="Edit Form!"
      >
        <form onSubmit={previousAttendanceForm.onSubmit((values) => submitPreviousAttendance())}>
          <DatePicker placeholder="Select Date" label="Select Date" dropdownType="modal" clearable={false} {...previousAttendanceForm.getInputProps('date')} onChange={(e) => checkPreviousAttendanceRequest(e) } withAsterisk />
          <p className="error-message">{previousErrorMessage}</p>
          <Radio.Group
            label="Select Employee Status"
            withAsterisk
            {...previousAttendanceForm.getInputProps('type')}
          >
            <Radio value="absent" label="Absent" />
            <Radio value= "leave" label="Leave" />
            <Radio value="present" label="Present" />
          </Radio.Group>
          {renderTimeInOut()}
          <TextInput mt="sm" label="Note" placeholder="Note" {...previousAttendanceForm.getInputProps('note')}/>
          <Button color="red" type="button" ml={212} mt="sm" onClick={() => setOpenedEdit(false)}>
            Cancel
          </Button>
          <Button color="green" type="submit" ml="sm" loading={buttonLoading} disabled={disabled}>
            Save
          </Button>
        </form>
      </Modal> 
      <Pagination total={total} page={current_page} onChange={(e) => getEmployees(e)} className="justify-content-center" mt={15} />
    </>
  );
}