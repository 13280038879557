import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import { useCookies } from 'react-cookie';
import ErrorPage from "./errors/401";
import http from '../axios/index';
import { useParams } from 'react-router-dom';
import {
  EventApi,
  DateSelectArg,
  EventClickArg,
  EventContentArg,
  formatDate,
} from '@fullcalendar/core'

export default function Calender() {


  let { id } = useParams();


  // Events Data
  const [eventsData, setEventsData] = useState<
    Array<{
        title: string,
        start: string
    }>
  >([]);


  function getEmployee() {
    http.get(`employee/${id}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.attendances.map((attendance: any) => {
        if (attendance.name == 'time_in') {
          let time_in_attendance_start: any = attendance.date + 'T' + attendance.value;
          setEventsData((curData) => [...curData, { title: 'Time In', start: time_in_attendance_start }]);
        }
        if (attendance.name == 'time_out') {
          let time_out_attendance_start: any = attendance.date + 'T' + attendance.value;
          setEventsData((curData) => [...curData, { title: 'Time Out', start: time_out_attendance_start }]);
        }
        if (attendance.name == 'leave') {
          setEventsData((curData) => [...curData, { title: 'Leave', start: ''}]);       
        }
        if (attendance.name == 'absent') {
          setEventsData((curData) => [...curData, { title: 'Absent', start: ''}]);
        }
      });
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  function renderEventContent(eventInfo: any) {
    debugger
    if (eventInfo.event.title == 'absent') {
      return (
        <>
          <p className="danger">{eventInfo.event.title}</p>
        </>
      )
    } else if (eventInfo.event.title == 'leave') {
      return (
        <>
          <p className="warning">{eventInfo.event.title}</p>
        </>
      )
    } else {
      return (
        <>
          <p><strong>{eventInfo.event.title}</strong>:</p>
        </>
      )
    }
  }

  // On Page Load
  useEffect(()=>{
    getEmployee();
  },[]);

  const [cookies] = useCookies(['token', 'user', 'abilities']);
  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Calender' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }


  return (
    <div className="mt-20">
      <FullCalendar 
        plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
        initialView="dayGridMonth"
        headerToolbar={{left: 'prev,next today', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay'}}
        // initialDate :  moment().startOf('month').format('YYYY-MM-DD'),
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        initialEvents={eventsData}
        eventContent={renderEventContent}
      />
    </div>
  );
}
