import React, { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Button, Pagination, Modal, TextInput, Select, NumberInput, Notification } from '@mantine/core';
import { IconSearch, IconCheck, IconX } from '@tabler/icons';
import { useCookies } from 'react-cookie';
import ErrorPage from "./errors/401";
import { useForm } from '@mantine/form';
import http from '../axios/index';
import Moment from 'react-moment';

export default function Vendors() {

  const [cookies] = useCookies(['token', 'user', 'abilities']);
  
  const[search, setSearch] = useState("");

  // Dialogs
  const [openedDialog, setOpenedDialog] = useState(false);
  const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);

  // Dialog Title and Button
  const [title, setTitle] = useState("");
  const [button, setButton] = useState("");

  // Fetching
  const [fetching, setFetching] = useState(false);

  // Buttons Loading
  const [buttonLoading, setButtonLoading] = useState(false);

  // Branches Data
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);


  // On Page Load
  useEffect(()=>{
    getVendors(1);
  },[search]);

  // Vendor form
  const form = useForm({
    initialValues: {
      id: '',
      uuid: '',
      name: '',
      code: '',
      email: '',
      phone: '',
      address: '',
      ntn_number: '',
      strn_number: '',
      company_id: cookies.user.company_id,
      balance: 0,
      status: '1',
    },

    // Form Validations
    validate: {
      name: (value) => (value.length < 2 ? 'Name must have at least 2 letters' : null),
      // balance: (value) => (value ? null : 'Please Add Balance'),
    },
  });

  // Functions

  // Reset Form
  function resetForm() {
    form.reset();
    form.values.id = '';
    form.values.uuid = '';
    form.values.name = '';
    form.values.code = '';
    form.values.email ='';
    form.values.phone = '';
    form.values.address = '';
    form.values.ntn_number = '';
    form.values.strn_number = '';
    form.values.company_id = cookies.user.company_id;
    form.values.balance = 0;
    form.values.status = '1';
  }

  // Get Vendors
  function getVendors(page: any) {
    setFetching(true);
    http.get(`vendor/?page=${page}&search=${search}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setData(response.data.data.data);
      setTotal(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
      setFetching(false);
    });
  }  

  // Create Vendor
  const createVendor = () => {
    form.reset();
    setTitle('Create New Vendor!');
    setButton('Save');
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Add Vendor
  function addVendor() {
    setButtonLoading(true);
    http.post('vendor', form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      getVendors(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Vendor created successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.strn_number) {
          form.setFieldError('strn_number', error.response.data.errors.strn_number.shift());
        }
        if (error.response.data.errors.ntn_number) {
          form.setFieldError('ntn_number', error.response.data.errors.ntn_number.shift());
        }
        if (error.response.data.errors.phone) {
          form.setFieldError('phone', error.response.data.errors.phone.shift());
        }
        if (error.response.data.errors.email) {
          form.setFieldError('email', error.response.data.errors.email.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Edit Account Function
  const editVendor = ({id, uuid, name, code, email, phone, address, ntn_number, strn_number, status}: any) => {
    setTitle('Edit Vendor!');
    setButton('Update');
    form.reset();
    form.setFieldValue('id', id);
    form.setFieldValue('uuid', uuid);
    form.setFieldValue('code', (code ? code : ''));
    form.setFieldValue('name', name);
    form.setFieldValue('email', (email ? email : ''));
    form.setFieldValue('phone', (phone ? phone : ''));
    form.setFieldValue('address', (address ? address : ''));
    form.setFieldValue('ntn_number', (ntn_number ? ntn_number : ''));
    form.setFieldValue('strn_number', (strn_number ? strn_number : ''));
    form.setFieldValue('status', (status ? '1' : '0'));
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Update Vendor
  function updateVendor() {
    setButtonLoading(true);
    http.put(`vendor/${form.values.uuid}`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      getVendors(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Vendor updated successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.strn_number) {
          form.setFieldError('strn_number', error.response.data.errors.strn_number.shift());
        }
        if (error.response.data.errors.ntn_number) {
          form.setFieldError('ntn_number', error.response.data.errors.ntn_number.shift());
        }
        if (error.response.data.errors.phone) {
          form.setFieldError('phone', error.response.data.errors.phone.shift());
        }
        if (error.response.data.errors.email) {
          form.setFieldError('email', error.response.data.errors.email.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Select Vendor to delete
  const deleteVendor = ({uuid}: any) => {
    form.setFieldValue('uuid', uuid);
    setOpenedDeleteDialog(true);
  }

  // Delete Vendor
  function deleteSelectedVendor() {
    setButtonLoading(true);
    http.delete('vendor/' + form.values.uuid, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      getVendors(1);
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
      setNotificationType(true);
      setNotification('Vendor deleted successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      console.log(error);
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
    }) 
  }

  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Vendors' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  function renderBalanceInput() {
    if(button === 'Save') {
      return (
        <NumberInput
          mt="sm"
          label="Opening Balance"
          placeholder="Enter Opening Balance"
          {...form.getInputProps('balance')}
          hideControls
        />
      )
    }
  }

  return (
    <>
      {renderNotification()}
      <div className="title-section">
        <h2 className="d-inline-block mr-10">Vendors</h2>
        <div className="d-inline-block float-right-md mb-15">
          <Button onClick={() => createVendor()} color="green" className="mt-15" mr={10}>Create New Vendor</Button>
          <TextInput icon={<IconSearch />} placeholder="Search Vendor..." className="d-inline-block mt-15" style={{width: 200}} value={search} onChange={(e) => setSearch(e.target.value)} withAsterisk />
        </div>
      </div>  
      
      <DataTable
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        // provide data
        records={data}
        minHeight={90}
        fetching={fetching}
        // define columns
        columns={[
          {
            accessor: 'id',
            title: 'ID',
            textAlignment: 'center',
            width: 15,
          },
          { accessor: 'name' },
          { accessor: 'code' },
          {
            accessor: 'status',
            render: ({ status  }) => (
              <>{status === '1' ? 'Enable' : 'Disable'}</>
            )
          },
          {
            accessor: 'last updated',
            render: ({ updated_at  }) => (
              <Moment format="YYYY-MM-DD HH:mm" >{updated_at}</Moment>
            )
          },
          {
            accessor: 'actions',
            // this column has custom cell data rendering
            render: ({ id, uuid, name, code, address, email, phone, ntn_number, strn_number, status }: any) => (
              <>
                <Button onClick={() => editVendor({id, uuid, name, code, address, email, phone, ntn_number, strn_number, status})}>
                  Edit
                </Button>
                <Button ml={10} color="red" onClick={() => deleteVendor({uuid})}>
                  Delete
                </Button>
              </>
            ),
            textAlignment: 'center',
            width: 200,
          },
        ]}
      />

      <Modal overflow="outside"
        opened={openedDialog}
        onClose={() => setOpenedDialog(false)}
        title={title}
      >
        <form onSubmit={form.onSubmit((values) =>  button === 'Save' ? addVendor() : updateVendor())}>
          {renderNotification()}
          <TextInput mt="sm" label="Name" placeholder="Enter Name" withAsterisk {...form.getInputProps('name')} />
          <TextInput mt="sm" placeholder="Enter Code" label="Code" {...form.getInputProps('code')} />
          <TextInput mt="sm" label="Email" placeholder="Enter Email" {...form.getInputProps('email')} />
          <TextInput mt="sm" placeholder="Enter Phone" label="Phone" {...form.getInputProps('phone')} />
          <TextInput mt="sm" label="Address" placeholder="Enter Address" {...form.getInputProps('address')} />
          <TextInput mt="sm" placeholder="Enter NTN Number" label="NTN" {...form.getInputProps('ntn_number')} />
          <TextInput mt="sm" placeholder="Enter STRN Number" label="STRN" {...form.getInputProps('strn_number')} />
          {/*<TextInput mt="sm" label="Price List" placeholder="Enter Price List" {...form.getInputProps('price')} />*/}
          {renderBalanceInput()}
          <Select
            mt={12}
            label="Status"
            placeholder="Select Status"
            data={[
              { value: '1', label: 'Enable' },
              { value: '0', label: 'Disable' },
            ]}
            {...form.getInputProps('status')}
          />
          <div className="text-right">
            <Button color="red" type="button" mt="sm" onClick={() => setOpenedDialog(false)}>
              Cancel
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              {button}
            </Button>
          </div>
        </form> 
      </Modal>
      <Modal
        opened={openedDeleteDialog}
        onClose={() => setOpenedDeleteDialog(false)}
        title="Confirm!"
      >
        <form>
          <h3>Are You Sure You Want To Delete?</h3>
          <div className="text-right mt-15">
            <Button color="red" type="button" ml="sm" onClick={() => setOpenedDeleteDialog(false)}>
              No
            </Button>
            <Button color="green" type="button" ml="sm" onClick={() => deleteSelectedVendor()} loading={buttonLoading}>
              Yes
            </Button>
          </div>
        </form>
      </Modal>
    
      <Pagination total={total} page={current_page} onChange={(e) => getVendors(e)} className="justify-content-center" mt={15} />
    </>
  );
}