import { useState, useEffect } from 'react';
import {
  createStyles,
  Container,
  UnstyledButton,
  Group,
  Text,
  Menu,
  Burger,
  Select,
  Image
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconLogout,
  IconHeart,
  IconStar,
  IconMessage,
  IconSettings,
  IconPlayerPause,
  IconTrash,
  IconSwitchHorizontal,
  IconChevronDown,
  IconUser
} from '@tabler/icons';
import { useCookies } from 'react-cookie'
import http from '../../axios/index';
import { useNavigate } from "react-router-dom";
import Logo from '../../asset/images/craftconcept-logo-final.png';

const useStyles = createStyles((theme) => ({
  header: {
    paddingTop: theme.spacing.sm,
    borderBottom: `1px solid ${
      theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background
    }`,
  },

  mainSection: {
    paddingBottom: theme.spacing.sm,
  },

  user: {
    color: theme.white,
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',

    '&:hover': {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
        0.1
      ),
    },

    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('xs')]: {
      display: 'none',
    },
  },

  userActive: {
    backgroundColor: theme.fn.lighten(
      theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
      0.1
    ),
  },

  tabs: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  tabsList: {
    borderBottom: '0 !important',
  },

  tab: {
    fontWeight: 500,
    height: 38,
    color: theme.white,
    backgroundColor: 'transparent',
    borderColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,

    '&:hover': {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
        0.1
      ),
    },

    '&[data-active]': {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
        0.1
      ),
      borderColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
    },
  },
}));

export default function Header() {
  const { classes, theme, cx } = useStyles();
  const navigate = useNavigate();
  const [opened, { toggle }] = useDisclosure(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'user']);
  const user_id = cookies.user.id;
  const company_id = cookies.user.company_id;
  const [value, setValue] = useState<string | null>(company_id);
  const renderCompanySelect = () => {
    if (cookies.user.role === 'Super Admin') {
      return (
        <>
          <Select
            placeholder="Select Company"
            data={companiesData}
            value={value} 
            onChange={(e) => changeCompany(e)}
            nothingFound="No active company"
            className="desktop-view"
            mr="sm"
          />
        </>
      );
    }
  }

  // Companies with label and value
  const [companiesData, setCompaniesData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

  // On Page Load
  useEffect(()=>{
    if(cookies.user.role === 'Super Admin') {
      getCompanies();
    }
  },[]);

  // Get Companies
  function getCompanies() {
    http.get('all-active-companies', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((item: any) => setCompaniesData((curData) => [...curData, { value: item.id, label: item.name }]))
    });
  }

  function changeCompany(companyId: string | null) {
    setValue(companyId);
    http.post('change-company', {user_id: user_id, company_id: companyId}, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      removeCookie('user');
      setCookie('user', JSON.stringify(response.data));
      // window.location.reload();
    });
  }

  function logout() {
    http.get('logout', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      removeCookie('token');
      removeCookie('user');
    });
  }

  function changeAccount() {
    navigate('/profile'); 
  }

  function renderImage() {
    if(cookies.user.image) {
      return (
        <Image
          radius="lg"
          src={`${process.env.REACT_APP_WEB_URL}${cookies.user.image}`}
          alt={cookies.user.username}
          height={25}
          width={25}
        />
      );
    } else {
      return (
        <IconUser size={15} />
      );
    }
  }

  return (
    <div className={classes.header} style={{borderBottom: "none", width: "100%", paddingTop: "0px"}}>
      <div className='header-css'>
        <Group position="apart">
          <Image src={Logo} width={150} className="main-logo" />
          <div>
            {renderCompanySelect()}
            <Menu
              width={260}
              position="bottom-end"
              transition="pop-top-right"
              onClose={() => setUserMenuOpened(false)}
              onOpen={() => setUserMenuOpened(true)}
            >
              <Menu.Target>
                <UnstyledButton
                  // className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                >
                  <Group spacing={7}>
                    {renderImage()}
                    <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                      {cookies.user.username}
                    </Text>
                    <IconChevronDown size={12} stroke={1.5} />
                  </Group>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                {/*<Menu.Item icon={<IconHeart size={14} stroke={1.5} color={theme.colors.red[6]} />}>
                  Liked posts
                </Menu.Item>
                <Menu.Item icon={<IconStar size={14} stroke={1.5} color={theme.colors.yellow[6]} />}>
                  Saved posts
                </Menu.Item>
                <Menu.Item icon={<IconMessage size={14} stroke={1.5} color={theme.colors.blue[6]} />}>
                  Your comments
                </Menu.Item>

              <Menu.Label>Settings</Menu.Label>
              <Menu.Item icon={<IconSettings size={14} stroke={1.5} />}>Account settings</Menu.Item>*/}
              <Menu.Item icon={<IconSwitchHorizontal size={14} stroke={1.5} />} onClick={changeAccount}>
                Change account
              </Menu.Item>
              <Menu.Item icon={<IconLogout size={14} stroke={1.5} />} onClick={logout}>Logout</Menu.Item>

                {/*<Menu.Divider />

                <Menu.Label>Danger zone</Menu.Label>
                <Menu.Item icon={<IconPlayerPause size={14} stroke={1.5} />}>
                  Pause subscription
                </Menu.Item>
                <Menu.Item color="red" icon={<IconTrash size={14} stroke={1.5} />}>
                  Delete account
                </Menu.Item>*/}
              </Menu.Dropdown>
            </Menu>
          </div>  
        </Group>
        </div>
    </div>
  );
}