import React, { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Button, Pagination, Modal, TextInput, Select, NumberInput, Notification } from '@mantine/core';
import { IconSearch, IconCheck, IconX } from '@tabler/icons';
import { useCookies } from 'react-cookie';
import ErrorPage from "./errors/401";
import { useForm } from '@mantine/form';
import http from '../axios/index';
import Moment from 'react-moment';

export default function Customers() {

  const [cookies] = useCookies(['token', 'user', 'abilities']);
  
  const[search, setSearch] = useState("");

  // Dialogs
  const [openedDialog, setOpenedDialog] = useState(false);
  const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);

  // Dialog Title and Button
  const [title, setTitle] = useState("");
  const [button, setButton] = useState("");

  // Fetching
  const [fetching, setFetching] = useState(false);

  // Buttons Loading
  const [buttonLoading, setButtonLoading] = useState(false);

  // Branches Data
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);


  // On Page Load
  useEffect(()=>{
    getCustomers(1);
  },[search]);

  // Customer form
  const form = useForm({
    initialValues: {
      id: '',
      uuid: '',
      name: '',
      email: '',
      phone: '',
      address: '',
      business_name: '',
      ntn_number: '',
      gst_number: '',
      cnic_number: '',
      strn_number: '',
      company_id: cookies.user.company_id,
      balance: 0,
      status: '1',
    },

    // Form Validations
    validate: {
      name: (value) => (value.length < 2 ? 'Name must have at least 2 letters' : null),
      // balance: (value) => (value ? null : 'Please Add Balance'),
    },
  });

  // Functions

  // Reset Form
  function resetForm() {
    form.reset();
    form.values.id = '';
    form.values.uuid = '';
    form.values.name = '';
    form.values.email ='';
    form.values.phone = '';
    form.values.address = '';
    form.values.business_name = '';
    form.values.ntn_number = '';
    form.values.gst_number = '';
    form.values.cnic_number = '';
    form.values.strn_number = '';
    form.values.company_id = cookies.user.company_id;
    form.values.balance = 0;
    form.values.status = '1';
  }

  // Get Customers
  function getCustomers(page: any) {
    setFetching(true);
    http.get(`customer/?page=${page}&search=${search}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setData(response.data.data.data);
      setTotal(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
      setFetching(false);
    });
  }  

  // Create Customer
  const createCustomer = () => {
    form.reset();
    setTitle('Create New Customer!');
    setButton('Save');
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Add Customer
  function addCustomer() {
    setButtonLoading(true);
    http.post('customer', form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      getCustomers(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Customer created successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.strn_number) {
          form.setFieldError('strn_number', error.response.data.errors.strn_number.shift());
        }
        if (error.response.data.errors.ntn_number) {
          form.setFieldError('ntn_number', error.response.data.errors.ntn_number.shift());
        }
        if (error.response.data.errors.gst_number) {
          form.setFieldError('gst_number', error.response.data.errors.gst_number.shift());
        }
        if (error.response.data.errors.cnic_number) {
          form.setFieldError('cnic_number', error.response.data.errors.cnic_number.shift());
        }
        if (error.response.data.errors.phone) {
          form.setFieldError('phone', error.response.data.errors.phone.shift());
        }
        if (error.response.data.errors.email) {
          form.setFieldError('email', error.response.data.errors.email.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Edit Account Function
  const editCustomer = ({id, uuid, name, email, phone, address, business_name, ntn_number, gst_number, cnic_number, strn_number, status}: any) => {
    setTitle('Edit Customer!');
    setButton('Update');
    form.reset();
    form.setFieldValue('id', id);
    form.setFieldValue('uuid', uuid);
    form.setFieldValue('name', name);
    form.setFieldValue('email', (email ? email : ''));
    form.setFieldValue('phone', (phone ? phone : ''));
    form.setFieldValue('address', (address ? address : ''));
    form.setFieldValue('business_name', (business_name ? business_name : ''));
    form.setFieldValue('ntn_number', (ntn_number ? ntn_number : ''));
    form.setFieldValue('gst_number', (gst_number ? gst_number : ''));
    form.setFieldValue('cnic_number', (cnic_number ? cnic_number : ''));
    form.setFieldValue('strn_number', (strn_number ? strn_number : ''));
    form.setFieldValue('status', (status ? 'Registered' : 'Non-Registered'));
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Update Customer
  function updateCustomer() {
    setButtonLoading(true);
    http.put(`customer/${form.values.uuid}`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      getCustomers(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Customer updated successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.strn_number) {
          form.setFieldError('strn_number', error.response.data.errors.strn_number.shift());
        }
        if (error.response.data.errors.ntn_number) {
          form.setFieldError('ntn_number', error.response.data.errors.ntn_number.shift());
        }
        if (error.response.data.errors.gst_number) {
          form.setFieldError('gst_number', error.response.data.errors.gst_number.shift());
        }
        if (error.response.data.errors.cnic_number) {
          form.setFieldError('cnic_number', error.response.data.errors.cnic_number.shift());
        }
        if (error.response.data.errors.phone) {
          form.setFieldError('phone', error.response.data.errors.phone.shift());
        }
        if (error.response.data.errors.email) {
          form.setFieldError('email', error.response.data.errors.email.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Select Customer to delete
  const deleteCustomer = ({uuid}: any) => {
    form.setFieldValue('uuid', uuid);
    setOpenedDeleteDialog(true);
  }

  // Delete Customer
  function deleteSelectedCustomer() {
    setButtonLoading(true);
    http.delete('customer/' + form.values.uuid, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      getCustomers(1);
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
      setNotificationType(true);
      setNotification('Customer deleted successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      console.log(error);
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
    }) 
  }
  
  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Customers' );


  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  // function renderBalanceInput() {
  //   if(button === 'Save') {
  //     return (
  //       <NumberInput
  //         mt="sm"
  //         label="Opening Balance"
  //         placeholder="Enter Opening Balance"
  //         {...form.getInputProps('balance')}
  //         hideControls
  //       />
  //     )
  //   }
  // }

  return (
    <>
      {renderNotification()}
      <div className="title-section">
        <h2 className="d-inline-block mr-10">Customers</h2>
        <div className="d-inline-block float-right-md mb-15">
          <Button onClick={() => createCustomer()} color="green" className="mt-15" mr={10}>Create New Customer</Button>
          <TextInput icon={<IconSearch />} placeholder="Search Customer..." className="d-inline-block mt-15" style={{width: 200}} value={search} onChange={(e) => setSearch(e.target.value)} withAsterisk />
        </div>
      </div>  
      
      <DataTable
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        // provide data
        records={data}
        minHeight={90}
        fetching={fetching}
        // define columns
        columns={[
          {
            accessor: 'id',
            title: 'ID',
            textAlignment: 'center',
            width: 15,
          },
          { accessor: 'name' },
          // {
          //   accessor: 'status',
          //   render: ({ status  }) => (
          //     <>{status === '1' ? 'Registered' : 'Non-Registered'}</>
          //   )
          // },
          {
            accessor: 'last updated',
            render: ({ updated_at  }) => (
              <Moment format="YYYY-MM-DD HH:mm" >{updated_at}</Moment>
            )
          },
          {
            accessor: 'actions',
            // this column has custom cell data rendering
            render: ({ id, uuid, name, address, business_name, email, phone, ntn_number, gst_number, cnic_number, strn_number, status }: any) => (
              <>
                <Button onClick={() => editCustomer({id, uuid, name, address, business_name, email, phone, ntn_number, gst_number, cnic_number, strn_number, status})}>
                  Edit
                </Button>
                <Button ml={10} color="red" onClick={() => deleteCustomer({uuid})}>
                  Delete
                </Button>
              </>
            ),
            textAlignment: 'center',
            width: 200,
          },
        ]}
      />

      <Modal overflow="outside"
        opened={openedDialog}
        onClose={() => setOpenedDialog(false)}
        title={title}
      >
        <form onSubmit={form.onSubmit((values) =>  button === 'Save' ? addCustomer() : updateCustomer())}>
          {renderNotification()}
          <TextInput mt="sm" label="Name" placeholder="Enter Name" withAsterisk {...form.getInputProps('name')} />
          <TextInput mt="sm" label="Business Name" placeholder="Enter Business Name" withAsterisk {...form.getInputProps('business_name')} />
          <TextInput mt="sm" label="Email" placeholder="Enter Email" {...form.getInputProps('email')} />
          <TextInput mt="sm" placeholder="Enter Phone" label="Phone" {...form.getInputProps('phone')} />
          <TextInput mt="sm" label="Address" placeholder="Enter Address" {...form.getInputProps('address')} />
          <TextInput mt="sm" placeholder="Enter NTN Number" label="NTN" {...form.getInputProps('ntn_number')} />
          <TextInput mt="sm" placeholder="Enter GST Number" label="GST" {...form.getInputProps('gst_number')} />
          <TextInput mt="sm" placeholder="Enter CNIC Number" label="CNIC" {...form.getInputProps('cnic_number')} />
          <TextInput mt="sm" placeholder="Enter STRN Number" label="STRN" {...form.getInputProps('strn_number')} />
          <Select
            mt={12}
            label="Status"
            placeholder="Select Status"
            data={[
              { value: '1', label: 'Registered' },
              { value: '0', label: 'Non-Registered' },
            ]}
            {...form.getInputProps('status')}
          />
          <div className="text-right">
            <Button color="red" type="button" mt="sm" onClick={() => setOpenedDialog(false)}>
              Cancel
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              {button}
            </Button>
          </div>
        </form> 
      </Modal>
      <Modal
        opened={openedDeleteDialog}
        onClose={() => setOpenedDeleteDialog(false)}
        title="Confirm!"
      >
        <form>
          <h3>Are You Sure You Want To Delete?</h3>
          <div className="text-right mt-15">
            <Button color="red" type="button" ml="sm" onClick={() => setOpenedDeleteDialog(false)}>
              No
            </Button>
            <Button color="green" type="button" ml="sm" onClick={() => deleteSelectedCustomer()} loading={buttonLoading}>
              Yes
            </Button>
          </div>
        </form>
      </Modal>
    
      <Pagination total={total} page={current_page} onChange={(e) => getCustomers(e)} className="justify-content-center" mt={15} />
    </>
  );
}