import { createStyles, Card, Text, SimpleGrid, UnstyledButton, Group, Grid} from '@mantine/core';
import {
  IconCategory2,
  IconSitemap,
  IconFileInvoice,
  IconReport,
  IconUsers,
  IconGitBranchDeleted,
  IconUserCheck,
  IconCoins,
  IconSchool,
} from '@tabler/icons';
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';


// Dashboard Quick Links
const mockdata = [
  { title: 'Customers', icon: IconUsers, color: 'violet', link: '/customers' },
  { title: 'Vendors', icon: IconUsers, color: 'indigo', link: '/vendors' },
  { title: 'Agents', icon: IconUsers, color: 'blue', link: '/commission-agents' },
  { title: 'Categories', icon: IconCategory2, color: 'green', link: '/categories' },
  { title: 'Items', icon: IconSitemap, color: 'teal', link: '/items' },
  { title: 'Invoices', icon: IconFileInvoice, color: 'cyan', link: '/sales/create' },
  { title: 'Ledger', icon: IconReport, color: 'pink', link: '/' },
  { title: 'Users', icon: IconUsers, color: 'red', link: '/users' },
  { title: 'Roles', icon: IconUserCheck, color: 'red', link: '' },
  { title: 'Premissions', icon: IconCoins, color: 'red', link: '' },
  { title: 'Companies', icon: IconSchool, color: 'red', link: '/companies' },
  { title: 'Branches', icon: IconGitBranchDeleted, color: 'orange', link: '/branches' },
];

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: theme.radius.md,
    height: 90,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease, transform 100ms ease',

    '&:hover': {
      boxShadow: `${theme.shadows.md} !important`,
      transform: 'scale(1.05)',
    },
  },
}));
export default function Dashboard() {
  const navigate = useNavigate();
  const { classes, theme } = useStyles();
  const [cookies] = useCookies(['user', 'abilities']);
  const abilities = cookies.abilities;

  function assignQuickLink(item: any, abilities: any) {
    let ability_exits: any = abilities.find( ( name : any) => name === item.title );
    if (!ability_exits) {
      return (
        <>
        </>
      );
    } else {
      return (
        <UnstyledButton key={item.title} className={classes.item} onClick={() => navigate(item.link)}>
          <item.icon color={theme.colors[item.color][6]} size={32} />
          <Text size="xs" mt={7}>
            {item.title}
          </Text>
        </UnstyledButton>
      );
    }
  }

  const items = mockdata.map((item) => assignQuickLink(item, abilities));

  return (
    <>
      <Card withBorder radius="md" className={classes.card} mt={40}>
        <Group position="apart">
          <Text className={classes.title}>Quick Links</Text>
        </Group>
        <SimpleGrid
          cols={6}
          mt="md"
          breakpoints={[
            { maxWidth: 980, cols: 3, spacing: 'md' },
        ]}>
          {items}
        </SimpleGrid>
      </Card>
      <Grid mt="md">
        <Grid.Col xs={9}>
          <Card withBorder radius="md" className={classes.card}>
            <Group position="apart">
              <Text className={classes.title}>Sales Chart</Text>
            </Group>
          </Card>
        </Grid.Col>
        <Grid.Col xs={3}>
          <Card withBorder radius="md" className={classes.card}>
            <Group position="apart">
              <Text className={classes.title}>Top Products (December 2020)</Text>
            </Group>
          </Card>
        </Grid.Col>
      </Grid>
    </>
  );
}
