import App from "./App";
import Dashboard from "./pages/Dashboard";
import Attendance from "./pages/Attendance";
import Calender from "./pages/FullCalender";
import Bearing from "./pages/Bearing";
import Accounts from "./pages/Accounts";
import Users from "./pages/Users";
import Employees from "./pages/Employees";
import Companies from "./pages/Companies";
import Branches from "./pages/Branches";
import Departments from "./pages/Departments";
import Designations from "./pages/Designations";
import Items from "./pages/Items";
import Customers from "./pages/Customers";
import InvoicePrint from "./pages/InvoicePrint";
import Category from "./pages/Category";
import Vendors from "./pages/Vendors";
import ReceivePayments from "./pages/ReceivePayments";
import Abilities from "./pages/Abilities";
import Purchase from "./pages/Purchase";
import CommissionAgents from "./pages/CommissionAgents";
import Sales from "./pages/Sales";
import Units from "./pages/Units";
import CreateSalesInvoice from "./pages/CreateSalesInvoice";
import Weights from "./pages/Weights";
import CreatePurchaseInvoice from "./pages/CreatePurchaseInvoice";
import ErrorPage from "./pages/errors/404";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import {
  createBrowserRouter,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "attendance",
        element: <Attendance />,
      },
      {
        path: "calender/:id",
        element: <Calender />,
      },
      {
        path: "bearing",
        element: <Bearing />,
      },
      {
        path: "accounts",
        element: <Accounts />,
      },
      {
        path: "employees",
        element: <Employees />,
      },
      {
        path: "companies",
        element: <Companies />,
      },
      {
        path: "branches",
        element: <Branches />,
      },
      {
        path: "departments",
        element: <Departments />,
      },
      {
        path: "designations",
        element: <Designations />,
      },
      {
        path: "items",
        element: <Items />,
      },
      {
        path: "customers",
        element: <Customers />,
      },
      {
        path: "categories",
        element: <Category />,
      },
      {
        path: "vendors",
        element: <Vendors />,
      },
      {
        path: "receive-payments",
        element: <ReceivePayments />,
      },
      {
        path: "abilities",
        element: <Abilities />,
      },
      {
        path: "purchase",
        element: <Purchase />,
      },
      {
        path: "sales",
        element: <Sales />,
      },
      {
        path: "units",
        element: <Units />,
      },
      {
        path: "weights",
        element: <Weights />,
      },
      {
        path: "/purchase/create",
        element: <CreatePurchaseInvoice />,
      },
      {
        path: "/sales/create",
        element: <CreateSalesInvoice />,
      },
      {
        path: "/invoice/:id",
        element: <InvoicePrint />,
      },
      {
        path: "commission-agents",
        element: <CommissionAgents />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
    ],
  },
]);

export default router;