import React, { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Button, Pagination, Modal, TextInput, Notification, Select, NumberInput} from '@mantine/core';
import { useCookies } from 'react-cookie';
import ErrorPage from "./errors/401";
import http from '../axios/index';
import { useForm } from '@mantine/form';
import { IconSearch, IconCheck, IconX } from '@tabler/icons';
import Moment from 'react-moment';

export default function Weights() {

  const [cookies, setCookie] = useCookies(['token', 'user']);

  const[search, setSearch] = useState("");

  // Fetching
  const [fetching, setFetching] = useState(false);

  // Buttons Loading
  const [buttonLoading, setButtonLoading] = useState(false);

  // Dialogs
  const [openedDialog, setOpenedDialog] = useState(false);
  const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);

  // Dialog Title and Button
  const [title, setTitle] = useState("");
  const [button, setButton] = useState("");

  // Weights Data
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);

  // Units with label and value
  const [unitsData, setUnitsData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);


  // On Page Load
  useEffect(()=>{
    getWeights(1);
    getUnits();
  },[search]);

  // Weight form
  const form = useForm({
    initialValues: {
      id: '',
      uuid: '',
      name: '',
      value: '',
      unit_id: '',
    },

    // Form Validations
    validate: {
      value: (value) => (value.length < 2 ? 'Weight field required' : null),
    },
  });

  // Functions

  // Get Units
  function getUnits() {
    http.get('units', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((item: any) => setUnitsData((curData) => [...curData, { value: item.id, label: item.name }]))
    });
  }

  // Get Weights
  function getWeights(page: any) {
    setFetching(true);
    http.get(`weights/?page=${page}&search=${search}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setData(response.data.data.data);
      setTotal(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
      setFetching(false);
    });
  }

  // Create Weight Function
  const createWeight = () => {
    form.reset();
    form.values.name = '';
    form.values.value = '';
    form.values.unit_id = '';
    setTitle('Create New Weight!');
    setButton('Save');
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Add Weight
  function addWeight() {
    setButtonLoading(true);
    http.post('weights', form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      form.reset();
      getWeights(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Weight created successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.name) {
          form.setFieldError('name', error.response.data.errors.name.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Edit Weight Function
  const editWeight = ({id, uuid, name, value, unit_id }: any) => {
    setTitle('Edit Weight!');
    setButton('Update');
    form.reset();
    form.setFieldValue('id', id);
    form.setFieldValue('uuid', uuid);
    form.setFieldValue('name', name);
    form.setFieldValue('value', value);
    form.setFieldValue('unit_id', unit_id);
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Update Weight
  function updateWeight() {
    setButtonLoading(true);
    http.put(`weights/${form.values.uuid}`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      form.reset();
      setButtonLoading(false);
      setOpenedDialog(false);
      getWeights(1);
      setNotificationType(true);
      setNotification('Weight updated successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      setNotificationType(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.name) {
          form.setFieldError('name', error.response.data.errors.name.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Select Weight to delete
  const deleteWeight = ({uuid}: any) => {
    form.values.uuid = uuid;
    setOpenedDeleteDialog(true);
  }

  // Delete Weight
  function deleteSelectedWeight() {
    setButtonLoading(true);
    http.delete('weights/' + form.values.uuid, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      console.log(response);
      form.reset();
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
      getWeights(1);
      setNotificationType(true);
      setNotification('Weight deleted successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      console.log(error);
      form.reset();
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
    }) 
  }

  function getUnitById({unitId}: any) {
    let unit = unitsData.find( ({id}: any) => id === unitId );
    if(unit) {
      debugger
      return unit.label;
    }
  }

  const abilities = cookies.user.abilities;
  const ability_exits = abilities.find( ({ name }: any) => name === 'Weights' );

  if (!ability_exits) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  return (
    <>
      {renderNotification()}
      <div className="title-section">
        <h2 className="d-inline-block mr-10">Weights</h2>
        <div className="d-inline-block float-right-md mb-15">
          <Button onClick={() => createWeight()} color="green" className="mt-15" mr={10}>Create New Weight</Button>
          <TextInput icon={<IconSearch />} placeholder="Search Weight..." className="d-inline-block mt-15" style={{width: 200}} value={search} onChange={(e) => setSearch(e.target.value)} withAsterisk />
        </div>
      </div> 
      <DataTable
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        records={data}
        minHeight={90}
        fetching={fetching}
        columns={[
          {
            accessor: 'id',
            title: 'ID',
            textAlignment: 'center',
            width: 15,
          },
          {
            accessor: 'unit',
            render: ({ unit  }: any) => (
              <>{unit.name}</>
            )
          },
          {
            accessor: 'value',
          },
          {
            accessor: 'Last updated',
            render: ({ updated_at  }) => (
              <Moment format="YYYY-MM-DD HH:mm" >{updated_at}</Moment>
            )
          },
          {
            accessor: 'actions',
            textAlignment: 'center',
            // this column has custom cell data rendering
            render: ({ id, uuid, name, value, unit_id  }) => (
              <>
                <Button onClick={() => editWeight({id, uuid, name, value, unit_id })}>
                  Edit
                </Button>
                <Button ml={10} color="red" onClick={() => deleteWeight({uuid})}>
                  Delete
                </Button>
              </>
            ),
            width: 200,
          },
        ]}
      />

      <Modal overflow="outside"
        opened={openedDialog}
        onClose={() => setOpenedDialog(false)}
        title={title}
      >
        <form onSubmit={form.onSubmit((values) =>  button === 'Save' ? addWeight() : updateWeight())}>
          {renderNotification()}
          <Select
            mt="sm"
            label="Unit"
            placeholder="Select Unit"
            data={unitsData}
            withAsterisk
            {...form.getInputProps('unit_id')}
          />
          <NumberInput mt="sm" label="Value" placeholder="Value" withAsterisk {...form.getInputProps('value')} />
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedDialog(false)}>
              Cancel
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              {button}
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        opened={openedDeleteDialog}
        onClose={() => setOpenedDeleteDialog(false)}
        title="Confirm!"
      >
        <form>
          <h3>Are You Sure You Want To Delete?</h3>
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedDeleteDialog(false)}>
              No
            </Button>
            <Button color="green" type="button" ml="sm" onClick={() => deleteSelectedWeight()} loading={buttonLoading}>
              Yes
            </Button>
          </div>
        </form>
      </Modal>
    
      <Pagination total={total} page={current_page} onChange={(e) => getWeights(e)} className="justify-content-center" mt={15} />
    </>
  );
}