import { Navbar, Group, ScrollArea, createStyles, Image } from '@mantine/core';
import {
  IconNotes,
  IconCalendarStats,
  IconGauge,
  IconPresentationAnalytics,
  IconFileAnalytics,
  IconSwitchHorizontal,
  IconLogout
} from '@tabler/icons';
import { LinksGroup } from './NavbarLinksGroup';
import Logo from '../../asset/images/cropped-craft-concepts-logo-150x150.png';

const mockdata = [
  { label: 'Dashboard', icon: IconGauge, link: '/' },
  { label: 'Attendance', icon: IconGauge, link: '/attendance' },
  { label: 'Employees', icon: IconGauge, link: '/employees' },
  { label: 'Bearing', icon: IconGauge, link: '/bearing' },
  { label: 'Accounts', icon: IconGauge, link: '/accounts' },
  { label: 'Departments', icon: IconGauge, link: '/departments' },
  { label: 'Designations', icon: IconGauge, link: '/designations' },
  { label: 'Companies', icon: IconGauge, link: '/companies' },
  { label: 'Branches', icon: IconGauge, link: '/branches' },
  { label: 'Categories', icon: IconGauge, link: '/categories' },
  { label: 'Vendors', icon: IconGauge, link: '/vendors' },
  { label: 'Commission Agents', icon: IconGauge, link: '/commission-agents' },
  {
    label: 'Items',
    icon: IconNotes,
    links: [
      { label: 'Items', link: '/items' },
      { label: 'Categories', link: '/categories' },
    ],
  },
  {
    label: 'Sales',
    icon: IconCalendarStats,
    links: [
      { label: 'Customers', link: '/customers' },
      { label: 'Sale Invoice', link: '/sales' },
      { label: 'Receive Payment', link: '/receive-payments' },
    ],
  },
  {
    label: 'Purchases',
    icon: IconCalendarStats,
    links: [
      { label: 'Purchase Invoice', link: '/' },
      { label: 'Payment Mode', link: '/' },
    ],
  },
  {
    label: 'Reports',
    icon: IconCalendarStats,
    links: [
      { label: 'General Ledger', link: '/' },
      { label: 'Customer Balances', link: '/' },
      { label: 'Vendor Balances', link: '/' },
    ],
  },
  { label: 'Users', icon: IconPresentationAnalytics, link: '/users' },
  { label: 'Abilities', icon: IconFileAnalytics, link: '/abilities' },
  { label: 'Units', icon: IconFileAnalytics, link: '/units' },
  { label: 'Weights', icon: IconFileAnalytics, link: '/weights' },
];

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  linkIcon: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    marginRight: theme.spacing.sm,
  },
}));

export default function SideBar() {
  const { classes } = useStyles();
  const links = mockdata.map((item) => <LinksGroup {...item} key={item.label} />);

  return (
    <>
    <Navbar height={550} width={{ sm: 250, lg: 300 }} p="sm" className={classes.navbar}>
      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section>

      {/*<Navbar.Section className={classes.footer}>
        <a href="#" className={classes.link} onClick={(event) => event.preventDefault()}>
          <IconSwitchHorizontal className={classes.linkIcon} stroke={1.5} />
          <span>Change account</span>
        </a>

        <a href="#" className={classes.link} onClick={(event) => event.preventDefault()}>
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Logout</span>
        </a>
      </Navbar.Section>*/}
    </Navbar>
    </>
  );
}