import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import { Button, TextInput, PasswordInput, Card, Notification, FileInput } from '@mantine/core';
import { useCookies } from 'react-cookie';
import http from '../axios/index';
import { IconCheck, IconX } from '@tabler/icons';

export default function Profile() {

  const [cookies, setCookie] = useCookies(['token', 'user']);

  // Buttons Loading
  const [buttonLoading, setButtonLoading] = useState(false);

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);

  // Image File
  const [file, setFile] = useState<File | null>(null);

  // User Form
  const form = useForm({
    initialValues: { 
      firstname: cookies.user.firstname, 
      lastname: cookies.user.lastname, 
      username: cookies.user.username, 
      email: cookies.user.email,
      phone: cookies.user.phone ? cookies.user.phone : '' ,
      address: cookies.user.address ? cookies.user.address : '', 
      password: '', 
      confirmpassword: ''
    },

    // functions will be used to validate values at corresponding key
    validate: {
      firstname: (value) => (value.length < 2 ? 'First Name Required' : null),
      lastname: (value) => (value.length < 2 ? 'Last Name Required' : null),
      username: (value) => (value.length < 2 ? 'User Name Required' : null),
      email: (value) => (value.length < 3 ? 'Email field Required' : (/^\S+@\S+$/.test(value) ? null : 'Invalid email')),
      phone: (value) => (value.length < 11 ? 'Phone Number must have at least 11 digits' : null),
      confirmpassword: (value, values) =>
        value !== values.password ? 'Passwords did not match' : null,
    },
  });

  // Update Profile
  function updateProfile(file: any) {
    setButtonLoading(true);
    const formData = new FormData();
    formData.append("id", cookies.user.id);
    formData.append("firstname", form.values.firstname);
    formData.append("lastname", form.values.lastname);
    formData.append("username", form.values.username);
    formData.append("email", form.values.email);
    formData.append("phone", form.values.phone);
    formData.append("address", (form.values.address !== "" ? form.values.address : ""));
    formData.append("password", form.values.password);
    formData.append("image", file);
    http.post('update-profile', formData, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      setCookie('user', JSON.stringify(response.data.data));
      setButtonLoading(false);
      setNotification('User profile updated successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.phone) {
          form.setFieldError('phone', error.response.data.errors.phone.shift());
        }
        if (error.response.data.errors.email) {
          form.setFieldError('email', error.response.data.errors.email.shift());
        }
        if (error.response.data.errors.username) {
          form.setFieldError('username', error.response.data.errors.username.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    })  
  }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  return (
    <>
      <Card withBorder mt="sm">
        {renderNotification()}
        <h2>Edit Profile</h2>
        <form onSubmit={form.onSubmit((values) => updateProfile(file))}>
          <TextInput label="First Name" placeholder="Enter First Name" {...form.getInputProps('firstname')} />
          <TextInput mt="sm" label="Last Name" placeholder="Enter Last Name" {...form.getInputProps('lastname')} />
          <TextInput mt="sm" label="User Name" placeholder="Enter User Name" {...form.getInputProps('username')} />
          <TextInput mt="sm" label="Email" placeholder="Enter Email" {...form.getInputProps('email')} />
          <TextInput mt="sm" label="Mobile Number" placeholder="Enter Mobile Number" type="number" withAsterisk {...form.getInputProps('phone')} />
          <TextInput mt="sm" label="Address" placeholder="Enter Address" {...form.getInputProps('address')} />
          <PasswordInput mt="sm" label="Password" placeholder="Enter Password" {...form.getInputProps('password')}/>
          <PasswordInput mt="sm" label="Confirm Password" placeholder="Confirm Password" {...form.getInputProps('confirmpassword')}/>
          <FileInput
            mt="sm"
            placeholder="Pick file"
            label="User Image"
            accept="image/png,image/jpeg"
            clearable={true}
            value={file}
            onChange={setFile}
          />
          <div className="text-right">
            <Button type="submit" mt="sm" loading={buttonLoading}>
              Save
            </Button>
          </div>
        </form>
      </Card>
    </>
  );
}