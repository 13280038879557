import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  // Checkbox,
  Button,
  Title,
  // Text,
  // Anchor,
} from '@mantine/core';
import { useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie'
import { useForm } from '@mantine/form';
import LoginPageImage from '../asset/images/LoginPageImage.jpg';

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 900,
    backgroundSize: 'cover',
    backgroundImage:
      `url(${LoginPageImage})`,
  },

  form: {
    borderRight: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: 900,
    maxWidth: 450,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%',
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    width: 120,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function Login() {
  const [cookies, setCookie] = useCookies(['token', 'user', 'abilities']);

  const [loading, setLoading] = useState(false);

  // Login form
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    // Form Validations
    validate: {
      email: (value) => (value.length < 3 ? 'Email field Required' : (/^\S+@\S+$/.test(value) ? null : 'Invalid email')),
      password: (value) => (value.length < 3 ? 'Password field Required' : null),
    },
  });
  
  function login() { 
    setLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/login`, form.values)
    .then((response) => {
      setCookie('token', response.data.data.token);
      setCookie('user', JSON.stringify(response.data.data.user));
      setCookie('abilities', JSON.stringify(response.data.data.user_abilities));
      setLoading(false);
      window.location.replace('/');
    })
    .catch((error) => {
      if (error.response.data.errors) {
        form.setFieldError('email', error.response.data.errors.email.shift());
      } else {
        form.setFieldError('password', error.response.data.message);
      }
      setLoading(false);
    })
  }
  const { classes } = useStyles();
  return (
    <>
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <Title order={2} className={classes.title} align="center" mt="md" mb={50}>
          Welcome back to Mantine!
        </Title>
        <form onSubmit={form.onSubmit((values) => login())}>
          <TextInput label="Email" withAsterisk {...form.getInputProps('email')} placeholder="Enter Email" size="md" />
          <PasswordInput label="Password" withAsterisk {...form.getInputProps('password')} placeholder="Enter password" mt="md" size="md" />
          {/*<Checkbox label="Keep me logged in" mt="xl" size="md" />*/}
          <Button mt="xl" size="md" type="submit" loading={loading}>Login</Button>
        </form>

        {/*<Text align="center" mt="md">
          Don&apos;t have an account?{' '}
          <Anchor<'a'> href="#" weight={700} onClick={(event) => event.preventDefault()}>
            Register
          </Anchor>
        </Text>*/}
      </Paper>
    </div>
    </>
  );
}
