import React, { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Button, Pagination, Modal, TextInput, Notification } from '@mantine/core';
import { useCookies } from 'react-cookie';
import ErrorPage from "./errors/401";
import http from '../axios/index';
import { useForm } from '@mantine/form';
import { IconSearch, IconCheck, IconX } from '@tabler/icons';
import Moment from 'react-moment';

export default function Designations() {

  const [cookies] = useCookies(['token', 'user', 'abilities']);

  const[search, setSearch] = useState("");

  // Fetching
  const [fetching, setFetching] = useState(false);

  // Buttons Loading
  const [buttonLoading, setButtonLoading] = useState(false);

  // Dialogs
  const [openedDialog, setOpenedDialog] = useState(false);
  const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);

  // Dialog Title and Button
  const [title, setTitle] = useState("");
  const [button, setButton] = useState("");

  // Designation Data
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);


  // On Page Load
  useEffect(()=>{
    getDesignations(1);
  },[search]);

  // Designation form
  const form = useForm({
    initialValues: {
      uuid: '',
      name: '',
      description: '',
      company_id: cookies.user.company_id
    },

    // Form Validations
    validate: {
      name: (value) => (value.length < 2 ? 'Designation name must have at least 2 letters' : null),
    },
  });

  // Functions

  // Get Designations
  function getDesignations(page: any) {
    setFetching(true);
    http.get(`position/?page=${page}&search=${search}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setData(response.data.data.data);
      setTotal(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
      setFetching(false);
    });
  }

  // Create Designation Function
  const createDesignation = () => {
    form.reset();
    form.values.name = '';
    form.values.description = '';
    setTitle('Create New Designation!');
    setButton('Save');
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Add Designation
  function addDesignation() {
    setButtonLoading(true);
    http.post(`position`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      form.reset();
      getDesignations(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Designation created successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      setNotificationType(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.name) {
          setNotification(error.response.data.errors.name.shift());
        }
      } else {
        setNotification(error.response.data.message);
      }
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    }) 
  }

  // Edit Designation Function
  const editDesignation = ({uuid, name, description}: any) => {
    setTitle('Edit Designation!');
    setButton('Update');
    form.reset();
    form.setFieldValue('uuid', uuid);
    form.setFieldValue('name', name);
    form.setFieldValue('description', description);
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Update Designation
  function updateDesignation() {
    setButtonLoading(true);
    http.put(`position/${form.values.uuid}`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      form.reset();
      setButtonLoading(false);
      setOpenedDialog(false);
      getDesignations(1);
      setNotificationType(true);
      setNotification('Designation updated successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      setNotificationType(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.name) {
          setNotification(error.response.data.errors.name.shift());
        }
      } else {
        setNotification(error.response.data.message);
      }
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    }) 
  }

  // Select Designation to delete
  const deleteDesignation = ({uuid}: any) => {
    form.values.uuid = uuid;
    setOpenedDeleteDialog(true);
  }

  // Delete Designation
  function deleteSelectedDesignation() {
    setButtonLoading(true);
    http.delete('position/' + form.values.uuid, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      console.log(response);
      form.reset();
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
      getDesignations(1);
      setNotificationType(true);
      setNotification('Designation deleted successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      console.log(error);
      form.reset();
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
    }) 
  }

  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Designations' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  return (
    <>
      {renderNotification()}
      <div className="title-section">
        <h2 className="d-inline-block mr-10">Designations</h2>
        <div className="d-inline-block float-right-md mb-15">
          <Button onClick={() => createDesignation()} color="green" className="mt-15" mr={10}>Create New Designation</Button>
          <TextInput icon={<IconSearch />} placeholder="Search Designation..." className="d-inline-block mt-15" style={{width: 200}} value={search} onChange={(e) => setSearch(e.target.value)} withAsterisk />
        </div>
      </div> 
      <DataTable
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        records={data}
        minHeight={90}
        fetching={fetching}
        columns={[
          {
            accessor: 'id',
            title: 'ID',
            textAlignment: 'center',
            width: 15,
          },
          { accessor: 'name' },
          { accessor: 'description' },
          {
            accessor: 'Last updated',
            render: ({ updated_at  }) => (
              <Moment format="YYYY-MM-DD HH:mm" >{updated_at}</Moment>
            )
          },
          {
            accessor: 'actions',
            textAlignment: 'center',
            // this column has custom cell data rendering
            render: ({ uuid, name, description }) => (
              <>
                <Button onClick={() => editDesignation({uuid, name, description})}>
                  Edit
                </Button>
                <Button ml={10} color="red" onClick={() => deleteDesignation({uuid})}>
                  Delete
                </Button>
              </>
            ),
            width: 200,
          },
        ]}
      />

      <Modal overflow="outside"
        opened={openedDialog}
        onClose={() => setOpenedDialog(false)}
        title={title}
      >
        <form onSubmit={form.onSubmit((values) =>  button === 'Save' ? addDesignation() : updateDesignation())}>
          {renderNotification()}
          <TextInput mt="sm" label="Name" placeholder="Designation Name" withAsterisk {...form.getInputProps('name')} />
          <TextInput mt="sm" label="Description" placeholder="Description" withAsterisk {...form.getInputProps('description')} />
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedDialog(false)}>
              Cancel
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              {button}
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        opened={openedDeleteDialog}
        onClose={() => setOpenedDeleteDialog(false)}
        title="Confirm!"
      >
        <form>
          <h3>Are You Sure You Want To Delete?</h3>
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedDeleteDialog(false)}>
              No
            </Button>
            <Button color="green" type="button" ml="sm" onClick={() => deleteSelectedDesignation()} loading={buttonLoading}>
              Yes
            </Button>
          </div>
        </form>
      </Modal>
    
      <Pagination total={total} page={current_page} className="justify-content-center" mt={15} />
    </>
  );
}