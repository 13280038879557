import React, { useState } from 'react';
import { DataTable } from 'mantine-datatable';
import { Button, Pagination, Modal, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import ErrorPage from "./errors/401";

export default function Purchase() {
  const navigate = useNavigate();
  
  function handleClick() {
    navigate("/purchase/create");
  }

  // Dialogs
  const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);



  // Ability Demo Data
  const data = [
    { sr: 1, name: 'Zubair', invoiceNo: 21, oderNo: '89', term: '', dueDate: '', amount: '12', price: 'recieved', balance: '1200', status: '0' },
    { sr: 2, name: 'Hafiz Ammar', invoiceNo: 34, oderNo: '90', term: '', dueDate: '', amount: '42', price: 'paid', balance: '2400', status: '1' },
    { sr: 3, name: 'Umar', invoiceNo: 23, oderNo: '66', term: '', dueDate: '', amount: '98', price: 'done', balance: '5500', status: '0' },
  ];

  
  const [cookies] = useCookies(['user']);
  const abilities = cookies.user.abilities;
  const ability_exist = abilities.find( ({ name }: any) => name === 'Purchases' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  return (
    <>
      <div className="title-section">
        <h2 className="d-inline-block mr-10">Purchases</h2>
        <div className="d-inline-block float-right-md mb-15">
          <Button onClick={handleClick} color="green" className="mt-15" mr={10}>Create Purchase Invoice</Button>
          <TextInput icon={<IconSearch />} placeholder="Search Ability..." className="d-inline-block mt-15" style={{width: 200}} />
        </div>
      </div>
        
      <DataTable
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        // provide data
        records={data}
        // define columns
        columns={[
          {
            accessor: 'sr',
            title: 'SR.',
          },
          {
            accessor: 'invoice no',
            title: 'Invoice No',
          },
          {
            accessor: 'oder no',
            title: 'Oder No',
          },
          {
            accessor: 'customer name',
            title: 'Customer Name',
          },
          {
            accessor: 'status',
            render: ({ status  }) => (
              <>{status === '1' ? 'Enable' : 'Disable'}</>
            )
          },
          {
            accessor: 'term',
          },
          {
            accessor: 'due date',
          },
          {
            accessor: 'amount',
            title: 'Amount',
          },
          {
            accessor: 'actions',
            // this column has custom cell data rendering
            render: ({ name, invoiceNo, oderNo, term, amount, price, balance, status }) => (
              <>
                <Button ml={10} color="red" onClick={() => setOpenedDeleteDialog(true)}>
                  Delete
                </Button>
              </>
            ),
          },
          { accessor: 'last updated' },
        ]}
      />

      <Modal
        opened={openedDeleteDialog}
        onClose={() => setOpenedDeleteDialog(false)}
        title="Confirm!"
      >
        <form>
          <h3>Are You Sure You Want To Delete?</h3>
          <Button color="red" type="button" ml={260} mt="sm" onClick={() => setOpenedDeleteDialog(false)}>
            Yes
          </Button>
          <Button color="green" type="button" ml="sm" onClick={() => setOpenedDeleteDialog(false)}>
            No
          </Button>
        </form>
      </Modal>
    
      <Pagination total={10} className="justify-content-center" mt={15} />
    </>
  );
}